import React from "react";
import { useTranslation } from "react-i18next";

interface SidebarProps {
  activeSection: string;
  handleSectionChange: (section: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  activeSection,
  handleSectionChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="sidebar">
      <button
        className={`sidebar-button ${
          activeSection === "followingDoctors" ? "active" : ""
        }`}
        onClick={() => handleSectionChange("followingDoctors")}
      >
        {t("Profile.Following_Doctors")}
      </button>
      <button
        className={`sidebar-button ${
          activeSection === "ratings" ? "active" : ""
        }`}
        onClick={() => handleSectionChange("ratings")}
      >
        {t("Ratings")}
      </button>
      <button
        className={`sidebar-button ${
          activeSection === "account" ? "active" : ""
        }`}
        onClick={() => handleSectionChange("account")}
      >
        {t("Account")}
      </button>
      <button
        className={`sidebar-button ${
          activeSection === "updatePassword" ? "active" : ""
        }`}
        onClick={() => handleSectionChange("updatePassword")}
      >
        {t("Profile.UpdatePassword")}
      </button>
    </div>
  );
};

export default Sidebar;
