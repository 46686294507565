import React, { useState, useEffect } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import "./DoctorDetails.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifyErr, notifySuccess } from "../../components/MessageUtils";
import { UserType, ReviewType, DoctorType } from "../../types";
import * as reviewService from "../../api/reviewService";
import { FaPhone, FaChevronDown, FaChevronUp } from "react-icons/fa";
import RatingSection from "./RatingSection";

interface doctorDetailsProps {
  selectedDoctor: null | UserType | any;
}

const DoctorDetails: React.FC<doctorDetailsProps> = ({ selectedDoctor }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;
  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const [error, setError] = useState<string | null>(null);
  const [isFollowing, setFollowing] = useState<boolean>(false);

  const [reviews, setReviews] = useState<ReviewType[]>([]);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const token = useSelector((state: any) => state.userInfo?.token);
  const doctorType = selectedDoctor.doctor_profile
    ? "doctor_profile"
    : "doctor";
  const [showReviews, setShowReviews] = useState(false);
  useEffect(() => {
    const fetchReviews = async () => {
      // Use the condition to determine the doctor ID
      const doctorId = selectedDoctor.doctor_profile?.id || selectedDoctor.id;

      if (doctorId) {
        const fetchedReviews = await reviewService.loadReviews(
          doctorId,
          token,
          doctorType
        );
        setReviews(fetchedReviews);
      }
    };
    fetchReviews();
  }, [selectedDoctor, token]);

  // useEffect(() => {
  //   const fetchReviews = async () => {
  //     if (selectedDoctor && selectedDoctor.id != null) {
  //       const reviews = await reviewService.loadReviews(
  //         selectedDoctor.id,
  //         token
  //       );
  //     }
  //     setReviews(reviews);
  //   };
  //   fetchReviews();
  // }, [selectedDoctor]);

  const handleSubmitReview = async () => {
    if (rating && reviewText) {
      if (selectedDoctor && selectedDoctor.id != null) {
        const newReview = await reviewService.addReview(
          selectedDoctor?.id,
          rating,
          reviewText,
          token
        );
        setReviews([...reviews, newReview]);
      }
    }
  };

  useEffect(() => {
    const checkFollowing = async () => {
      try {
        const response = await axios.get(
          `/api/users/follow/doctor/${selectedDoctor?.id}/is-following/${doctorType}/`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );

        setFollowing(response.data.isFollowing);
      } catch (error) {
        console.error(
          "An error occurred while checking following status",
          error
        );
      }
    };
    if (userInfo) {
      checkFollowing();
    }
  }, [selectedDoctor]);

  const contactNow = () => {
    navigate(`/${codeLng}/login/`);
  };

  const followDoctor = async (selectedDoctor: UserType) => {
    if (userInfo) {
      try {
        const newdoctorFollowing = {
          user: userInfo.id,
          doctor: selectedDoctor.id,
          doctor_type: doctorType,
        };
        const axiosConfig = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
          params: { user: userInfo },
        };

        await axios.post(
          `/api/users/follow/doctor/${selectedDoctor?.id}/`,
          newdoctorFollowing,
          axiosConfig
        );

        notifySuccess(t("doctor added successfully!"));
        setFollowing(true);
      } catch (error: any) {
        let errMsg = error.response.data.detail;
        notifyErr(t("Error." + errMsg));
      }
    } else {
      navigate(`/${codeLng}/login`);
    }
  };

  const getDescription = () => {
    if (doctorType === "doctor_profile") {
      return selectedDoctor?.doctor_profile?.description || "";
    } else {
      return selectedDoctor?.description || "";
    }
  };

  // const displayReviews =
  //   reviews.length >= 0 ? (
  //     reviews.map((review) => (
  //       <div key={review.id} className="review-item">
  //         <div className="reviewer-name">Reviewer ID: {review.user}</div>
  //         <div className="review-text">{review.review}</div>

  //         <div className="review-date">
  //           {new Date(review.created_at).toLocaleDateString()}
  //         </div>
  //       </div>
  //     ))
  //   ) : (
  //     <p>No reviews yet.</p>
  //   );

  const displayReviews = Array.isArray(reviews) ? (
    reviews.map((review) => (
      <div key={review.id} className="review-item">
        {/* <div className="reviewer-name">Reviewer ID: {review.user}</div> */}
        <div className="review-date">
          {new Date(review.created_at).toLocaleDateString()}
        </div>
        <div className="review-text">{review.review}</div>
      </div>
    ))
  ) : (
    <p>{t("Messages.No_reviews_yet.")}</p>
  );

  const toggleReviews = () => {
    setShowReviews(!showReviews);
  };

  return (
    <>
      <ToastContainer />
      {selectedDoctor && (
        <div className="bestabib__doctor-details">
          <div>
            <button
              className="bestabib__doctor-details-btn-addToMydoctors"
              onClick={() => followDoctor(selectedDoctor)}
              disabled={isFollowing ?? true}
            >
              {t("Doctor_Details.Follow")}
            </button>

            {error && <p>{error}</p>}
          </div>

          <div className="bestabib__doctor-details-card-content">
            <img
              // src={
              //   selectedDoctor?.doctor_profile?.image
              //     ? selectedDoctor?.doctor_profile?.image
              //     : selectedDoctor?.image
              // }
              src={
                selectedDoctor?.doctor_profile?.image
                  ? selectedDoctor?.doctor_profile?.image
                  : selectedDoctor?.gender === "F"
                  ? "https://tallshadowawsbucket.s3.eu-west-3.amazonaws.com/bestabib/default-doctor-f.jpg"
                  : "https://tallshadowawsbucket.s3.eu-west-3.amazonaws.com/bestabib/default-doctor-m.jpg"
              }
              alt={selectedDoctor.last_name}
              width={88}
              height={88}
              className="bestabib__doctor-details-card-photo"
            />
            <div className="bestabib__doctor-details-info">
              <h3 className="bestabib__doctor-details-card-info-title">
                Dr. {selectedDoctor.first_name} {selectedDoctor.last_name}
              </h3>
              <p className="bestabib__doctor-details-card-info-speciality">
                {selectedDoctor?.doctor_profile?.speciality}
              </p>
              <p className="bestabib__doctor-details-card-info-address">
                {selectedDoctor?.address?.street &&
                  selectedDoctor?.address?.street + ","}

                {selectedDoctor?.address?.postal_code &&
                  selectedDoctor?.address?.postal_code + ","}

                {selectedDoctor?.address?.city && selectedDoctor?.address?.city}
              </p>
            </div>
            <div className="bestabib__doctor-details-card-action">
              {userInfo ? (
                <>
                  {selectedDoctor?.phone && (
                    <p className="doctor-card-phone ">
                      {selectedDoctor?.phone}
                    </p>
                  )}
                  {/* {selectedDoctor?.email && (
                    <p className="doctor-card-email">{selectedDoctor?.email}</p>
                  )} */}
                </>
              ) : (
                <>
                  <button
                    className="bestabib__doctor-card-details_contact"
                    onClick={contactNow}
                  >
                    {t("Doctor.Call")}
                    <FaPhone />
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="bestabib__doctor-details-card-info-ratings">
            {selectedDoctor ? (
              <RatingSection
                doctorId={
                  selectedDoctor.doctor_profile?.id || selectedDoctor.id
                }
                doctorType={doctorType}
                isDoctorDetails={true}
              />
            ) : null}
          </div>
          <div className="bestabib__doctor-details-reviews">
            <button onClick={toggleReviews} className="reviews-toggle-button">
              {t("Messages.Reviews")}{" "}
              {showReviews ? <FaChevronUp /> : <FaChevronDown />}
            </button>

            {showReviews &&
              (reviews.length > 0 ? (
                reviews.map((review) => (
                  <div key={review.id} className="review-item">
                    <div className="review-date">
                      {new Date(review.created_at).toLocaleDateString()}
                    </div>
                    <div className="review-text">{review.review}</div>
                  </div>
                ))
              ) : (
                <p>{t("Messages.No_reviews_yet.")}</p>
              ))}
          </div>

          <hr />
          {getDescription() && (
            <>
              <h2 className="doctorMarok__doctor-details">
                {t("Doctor.Description")}
              </h2>
              <div
                className="bestabib__doctor-description-scroll"
                dangerouslySetInnerHTML={{
                  __html: getDescription(),
                }}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DoctorDetails;
