import { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import { Container } from "react-bootstrap";
import { Navbar } from "./components";
import { Footer } from "./containers";

import {
  RegisterScreen,
  LoginScreen,
  HomeScreen,
  TermsScreen,
  PrivacyScreen,
  AboutScreen,
  PrivacyCookiesScreen,
  ResetPasswordScreen,
  ResetPasswordConfirmScreen,
  ProfileScreen,
} from "./screens";
import DoctorDetailsPage from "./screens/doctors/DoctorDetailsPage";
import RedirectToDefaultLanguage from "./RedirectToDefaultLanguage";

function App() {
  return (
    <div className="page-overview">
      <Suspense fallback={null}>
        <Router>
          <Navbar />

          <Container className="wrapper">
            <main className="main">
              {/* <Navbar /> */}
              {/* <Header /> */}
              <Routes>
                <Route path="/" element={<RedirectToDefaultLanguage />} />
                <Route element={<HomeScreen />} path={`/:codeCountry/`} />
                <Route path="/:id/" element={<HomeScreen />} />
                {/* <Route
                  element={<DoctorDetailsPage />}
                  path={`/:codeCountry/*`}
                /> */}
                <Route
                  path="/:codeLng/:speciality/:doctorSlug"
                  element={<DoctorDetailsPage />}
                />

                <Route
                  element={<AboutScreen />}
                  path={`/:codeCountry/about/`}
                />

                <Route
                  element={<TermsScreen />}
                  path={`/:codeCountry/terms/`}
                />
                <Route
                  path={`/:codeCountry/privacy/`}
                  element={<PrivacyScreen />}
                />
                <Route
                  path={`/:codeCountry/cookies-policy/`}
                  element={<PrivacyCookiesScreen />}
                />

                <Route element={<PrivateRoutes />}>
                  <Route
                    path={`/:codeCountry/profile/`}
                    element={<ProfileScreen />}
                  />
                </Route>
                <Route
                  element={
                    <RegisterScreen location={undefined} history={undefined} />
                  }
                  path={`/:codeCountry/register/`}
                />
                <Route
                  element={<LoginScreen />}
                  path={`/:codeCountry/login/`}
                />
                <Route
                  element={<ResetPasswordScreen />}
                  path={`/:codeCountry/reset-password/`}
                />
                <Route
                  path={`/:codeCountry/password/reset/confirm/:uid/:token`}
                  element={<ResetPasswordConfirmScreen />}
                />
              </Routes>
            </main>
          </Container>

          <Footer />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
