import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_PASSWORD_RESET_CONFIRM_REQUEST,
  USER_PASSWORD_RESET_CONFIRM_FAIL,
  USER_PASSWORD_RESET_CONFIRM_SUCCESS,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_SUCCESS,
  UNFOLLOW_DOCTOR,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAIL,
} from "../constants/userConstants";

import axios from "axios";
import { t } from "i18next";
import { Dispatch } from "redux";
import {
  FETCH_FOLLOWING_DOCTORS_FAILURE,
  FETCH_FOLLOWING_DOCTORS_REQUEST,
  FETCH_FOLLOWING_DOCTORS_SUCCESS,
} from "../constants/doctorConstants";

export const login = (email: any, password: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    const { data } = await axios.post(
      "/api/users/login/",
      { username: email, password: password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error: any) {
    let userFriendlyError = "An error occurred.";
    if (error.response && error.response.data && error.response.data.username) {
      userFriendlyError = error.response.data.username[0];
    }

    userFriendlyError = t(`Error.${error.response.data.error}`);

    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.error
          ? userFriendlyError
          : error.message,
    });
  }
};

export const resetPassword = (email: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_PASSWORD_RESET_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/auth/users/reset_password/",
      { email },
      config
    );

    dispatch({
      type: USER_PASSWORD_RESET_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const resetPasswordConfirm =
  (uid: any, token: any, new_password: any, re_new_password: any) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: USER_PASSWORD_RESET_CONFIRM_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      // const axios = require("axios");
      const { data } = await axios.post(
        "/auth/users/reset_password_confirm/",
        { uid, token, new_password, re_new_password },
        config
      );
      dispatch({
        type: USER_PASSWORD_RESET_CONFIRM_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: USER_PASSWORD_RESET_CONFIRM_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const logout = () => (dispatch: any) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_LIST_RESET });
};

export const register =
  (name: string, email: string, password: string, isDoctor: boolean) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      // const axios = require("axios");
      const { data } = await axios.post(
        "/api/users/register/",
        {
          name: name,
          username: email,
          email: email,
          password: password,
          is_doctor: isDoctor,
        },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error: any) {
      let userFriendlyError = "An error occurred.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.username
      ) {
        userFriendlyError = error.response.data.username[0];
      }

      userFriendlyError = t(`Error.${userFriendlyError}`);

      dispatch({
        type: USER_REGISTER_FAIL,
        payload: userFriendlyError,
      });
    }
  };

export const getUserDetails =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: USER_DETAILS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/users/profile/`, config);

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: USER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

// export const updateUserProfile =
//   (user: any) => async (dispatch: any, getState: any) => {
//     try {
//       dispatch({
//         type: USER_UPDATE_PROFILE_REQUEST,
//       });

//       const {
//         userLogin: { userInfo },
//       } = getState();

//       const config = {
//         headers: {
//           "Content-type": "application/json",
//           Authorization: `Bearer ${userInfo.token}`,
//         },
//       };

//       // const axios = require("axios");
//       const { data } = await axios.put(
//         `/api/users/profile/update/`,
//         user,
//         config
//       );

//       dispatch({
//         type: USER_UPDATE_PROFILE_SUCCESS,
//         payload: data,
//       });

//       dispatch({
//         type: USER_LOGIN_SUCCESS,
//         payload: data,
//       });

//       localStorage.setItem("userInfo", JSON.stringify(data));
//     } catch (error: any) {
//       dispatch({
//         type: USER_UPDATE_PROFILE_FAIL,
//         payload:
//           error.response && error.response.data.detail
//             ? error.response.data.detail
//             : error.message,
//       });
//     }
//   };

// userActions.tsx
export const updateUserProfile =
  (user: FormData) => async (dispatch: Dispatch, getState: () => any) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // for (let [key, val] of user.entries()) {
      //   // If key is doctor_profile.image, log it.
      //   if (key === "address") {
      //     console.log(`Key: ${key}, Value: ${val}`);
      //   }
      // }

      // for (let [key, val] of user.entries()) {
      //   // If key is doctor_profile.image, log it.
      //   if (key === "doctor_profile") {
      //     console.log(`Key: ${key}, Value: ${val}`);
      //   }
      // }

      const { data } = await axios.put(`/api/users/profile/`, user, config);

      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listUsers = () => async (dispatch: any, getState: any) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const axios = require("axios");
    const { data } = await axios.get(`/api/users/`, config);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteUser = (id: any) => async (dispatch: any, getState: any) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const axios = require("axios");
    const { data } = await axios.delete(`/api/users/delete/${id}/`, config);

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateUser =
  (user: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const axios = require("axios");
      const { data } = await axios.put(
        `/api/users/update/${user._id}/`,
        user,
        config
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
      });

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

interface ParamsKeyword {
  keyword: string;
  page: number;
}
// export const fetchFollowingDoctors =
//   (query: ParamsKeyword) => async (dispatch: any, getState: any) => {
//     try {
//       dispatch({ type: FETCH_FOLLOWING_DOCTORS_REQUEST });

//       const {
//         userLogin: { userInfo },
//       } = getState();

//       const axiosConfig = {
//         headers: {
//           "Content-type": "application/json",
//           Authorization: `Bearer ${userInfo.token}`,
//         },
//         params: {
//           keyword: query.keyword,
//           page: query.page,
//         },
//       };

//       const { data } = await axios.get(
//         `/api/doctors/following-doctors/`,
//         axiosConfig
//       );

//       dispatch({
//         type: FETCH_FOLLOWING_DOCTORS_SUCCESS,
//         payload: data,
//       });
//     } catch (error: any) {
//       dispatch({
//         type: FETCH_FOLLOWING_DOCTORS_FAILURE,
//         payload:
//           error.response && error.response.data.detail
//             ? error.response.data.detail
//             : error.message,
//       });
//     }
//   };

// Action Creators
export const fetchFollowingDoctorsStart = () => ({
  type: FETCH_FOLLOWING_DOCTORS_REQUEST,
});

export const fetchFollowingDoctorsSuccess = (doctors: any) => ({
  type: FETCH_FOLLOWING_DOCTORS_SUCCESS,
  payload: doctors,
});

export const fetchFollowingDoctorsFailure = (error: any) => ({
  type: FETCH_FOLLOWING_DOCTORS_FAILURE,
  payload: error,
});

// export const fetchFollowingDoctors =
//   () => async (dispatch: any, getState: any) => {
//     return (dispatch: any) => {
//       const {
//         userLogin: { userInfo },
//       } = getState();

//       const config = {
//         headers: {
//           "Content-type": "application/json",
//           Authorization: `Bearer ${userInfo.token}`,
//         },
//       };
//       console.log("fetchFollowingDoctors action data----");
//       dispatch(fetchFollowingDoctorsStart());

//       fetch("/api/users/following-doctors/", {
//         method: "GET",
//         headers: {
//           "Content-type": "application/json",
//           Authorization: `Bearer ${userInfo.token}`,
//         },
//       })
//         .then((response) => response.json())
//         .then((data) => {
//           console.log("fetchFollowingDoctors data----", data);
//           dispatch(fetchFollowingDoctorsSuccess(data));
//         })
//         .catch((error) => {
//           dispatch(fetchFollowingDoctorsFailure(error.toString()));
//         });
//     };
//   };

export const fetchFollowingDoctors =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: FETCH_FOLLOWING_DOCTORS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/users/following-doctors/`, config);

      dispatch({
        type: FETCH_FOLLOWING_DOCTORS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: FETCH_FOLLOWING_DOCTORS_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

// export const unfollowDoctor = (doctorId: number) => {
//   return async (dispatch: any) => {
//     try {
//       const response = await axios.delete(
//         `/api/path-to-unfollow-endpoint/${doctorId}`
//       );

//       if (response.status === 200) {
//         dispatch({
//           type: UNFOLLOW_DOCTOR,
//           payload: doctorId,
//         });
//       } else {
//         // Handle any errors as needed
//         console.error("Error unfollowing doctor", response);
//       }
//     } catch (error) {
//       console.error("API error", error);
//       // Dispatch another action here if you want to handle errors in your Redux state
//     }
//   };
// };

export const unfollowDoctor = (doctorId: any, doctorType: string) => {
  return async (dispatch: any) => {
    try {
      const userInfoString = localStorage.getItem("userInfo");
      if (!userInfoString) {
        throw new Error("User not authenticated");
      }
      const userInfo = JSON.parse(userInfoString);
      const token = userInfo.token;

      const response = await axios.delete(
        `/api/users/unfollow/doctor/${doctorId}/${doctorType}/`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch({
          type: "UNFOLLOW_DOCTOR",
          payload: { doctorId, doctorType },
        });
      } else {
        console.error("Error unfollowing doctor", response);
      }
    } catch (error) {
      console.error("API error", error);
    }
  };
};

export const updateUserPassword =
  (currentPassword: string, newPassword: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_PASSWORD_REQUEST });

      const userInfoString = localStorage.getItem("userInfo");
      if (!userInfoString) {
        throw new Error("User not authenticated");
      }
      const userInfo = JSON.parse(userInfoString);

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        "/api/users/update/password/",
        { currentPassword, newPassword },
        config
      );

      dispatch({ type: UPDATE_USER_PASSWORD_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: UPDATE_USER_PASSWORD_FAIL,
        payload: error.response?.data?.error || error.message,
      });
      throw error; // Throw error to be caught in the component
    }
  };
