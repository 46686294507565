import React, { useState, FC, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { listDoctors } from "../../actions/doctorActions";
import { GoSearch, GoLocation } from "react-icons/go";
import { FaUserMd } from "react-icons/fa";
import { PiStethoscopeDuotone } from "react-icons/pi";
import "./SearchBox.css";

interface SearchParams {
  keyword?: string;
  specialty?: string;
  city?: string;
  doctor_name?: string;
  gender?: string;
}

type GenderIcons = {
  [key: string]: string;
};

const SearchBox: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [params, setParams] = useState<SearchParams>({
    keyword: "",
    specialty: "",
    city: "",
    doctor_name: "",
    gender: "A",
  });

  const genderIcons: GenderIcons = {
    A: "H/F",
    M: "Homme",
    F: "Femme",
  };

  const [selectedGender, setSelectedGender] = useState<"M" | "F" | "A">("A");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setParams((prevParams) => ({ ...prevParams, [name]: value }));
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedGender(value as "M" | "F" | "A");
    setParams((prevParams) => ({
      ...prevParams,
      gender: value === "A" ? "" : value,
    }));
  };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    dispatch<any>(listDoctors(params));
  };

  return (
    <form className="searchBox-Container" onSubmit={handleSearch}>
      <div className="inputs-container">
        <div className="input-icon-container">
          <input
            type="text"
            placeholder={t("Doctor.Specialty") || ""}
            name="specialty"
            value={params.specialty}
            onChange={handleInputChange}
            className="searchInput specialty"
          />
          <PiStethoscopeDuotone className="icon" />
        </div>
        <div className="input-icon-container">
          <input
            type="text"
            placeholder={t("Search.City") || ""}
            name="city"
            value={params.city}
            onChange={handleInputChange}
            className="searchInput city"
          />
          <GoLocation className="icon" />
        </div>
        <div className="input-icon-container">
          <input
            type="text"
            placeholder={t("Doctor.Doctor_Name") || ""}
            name="doctor_name"
            value={params.doctor_name}
            onChange={handleInputChange}
            className="searchInput doctor"
          />
          <FaUserMd className="icon" />
        </div>
      </div>
      <div className="checkbox-container">
        {["A", "M", "F"].map((gender) => (
          <label key={gender} className="gender-label">
            <input
              type="radio"
              name="gender"
              value={gender}
              checked={selectedGender === gender}
              onChange={handleGenderChange}
            />
            <span className="gender-icon">{genderIcons[gender]}</span>
          </label>
        ))}
      </div>
      <button type="submit" className="primary-cta">
        {t("Search.find_a_doctor")}
      </button>
    </form>
  );
};

export default SearchBox;
