import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getUserDetails,
  updateUserProfile,
  fetchFollowingDoctors,
  unfollowDoctor,
} from "../../actions/userActions";
import "./ProfileScreen.css";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "./Sidebar";
import FollowingDoctors from "./FollowingDoctors";
import UpdatePasswordSection from "./UpdatePasswordSection";

interface Doctor {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  specialty: string;
}

interface Rating {
  doctorId: number;
  rating: number;
  review: string;
}
type DoctorProfileType = {
  description?: string;
  speciality?: string;
};

const ProfileScreen = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("M");
  const [doctorImage, setDoctorImage] = useState<File | null>(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [doctorProfile, setDoctorProfile] = useState<DoctorProfileType>({
    description: "",
    speciality: "",
  });
  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
  });
  const [isDoctor, setIsDoctor] = useState(false);
  // const [followingDoctors, setFollowingDoctors] = useState<Doctor[]>([]);
  const fetchfollowingDoctors = useSelector(
    (state: any) => state.fetchFollowingDoctors
  );

  const { followingDoctors } = fetchfollowingDoctors;

  const [activeSection, setActiveSection] = useState("account"); // Default section

  const dispatch = useDispatch();
  const userDetails = useSelector((state: any) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched) {
      dispatch<any>(fetchFollowingDoctors(userInfo?.id));
      setFetched(true);
    } else {
      setName(user?.last_name || "");
      setFirstName(user?.first_name || "");
      setLastName(user?.last_name || "");
      setGender(user?.gender || "M");
      setEmail(user?.email || "");
      setIsDoctor(user?.is_doctor || false);
      setPhone(user?.phone || "");
      setDoctorImage(user?.doctor_profile?.image || null);
      setAddress(
        user?.address || {
          street: "",
          city: "",
          state: "",
          postal_code: "",
          country: "",
        }
      );
      setDoctorProfile({
        description: user?.doctor_profile?.description,
        speciality: user?.doctor_profile?.speciality,
      });
    }
  }, [dispatch, user, fetched]);

  useEffect(() => {
    if (activeSection === "account") {
      dispatch<any>(getUserDetails(userInfo?.id));
    }
  }, [dispatch]);

  // const unfollowDoctor = (doctorId: number) => {
  //   Remove doctor from following list
  //   setFollowingDoctors((prevDoctors) =>
  //     prevDoctors.filter((doc) => doc.id !== doctorId)
  //   );
  // };
  const handleUnfollowDoctor = (
    doctorId: any,
    doctorType: any
  ): Promise<void> => {
    return dispatch<any>(unfollowDoctor(doctorId, doctorType));
  };

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", user?.id);
    formData.append("last_name", lastName);
    formData.append("first_name", firstName);
    formData.append("gender", gender);
    formData.append("doctorImage", doctorImage || "");
    formData.append("username", email);
    formData.append("email", email);
    formData.append("is_doctor", String(isDoctor));
    formData.append("phone", phone);
    formData.append("address", JSON.stringify(address));
    formData.append("doctor_profile", JSON.stringify(doctorProfile));
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    dispatch<any>(updateUserProfile(formData))
      .then(() => {
        toast.success(t("Messages.Profile_updated_successfully!"));
      })
      .catch(() => {
        toast.error(t("Messages.Error_updating_profile!"));
      });
  };

  return (
    <div className="profile-container">
      <ToastContainer />
      <Sidebar
        activeSection={activeSection}
        handleSectionChange={handleSectionChange}
      />

      <div className="content">
        {activeSection === "followingDoctors" && (
          <FollowingDoctors
            doctors={followingDoctors}
            unfollowDoctor={handleUnfollowDoctor}
          />
        )}
        {/* {activeSection === "ratings" && (
        <Ratings
          userRatings={userRatings}
          handleRatingChange={handleRatingChange}
        />
      )} */}
        {activeSection === "account" && (
          <div>
            <h2 className="bestabib__profile-account-title">
              {t("AccountSettings")}
            </h2>
            <form
              onSubmit={submitHandler}
              className="bestabib__profile-account"
            >
              <div className="bestabib__profile-account-info">
                <div className="bestabib__profile-account-info-details">
                  <div className="bestabib__form-group">
                    <label>
                      <input
                        type="checkbox"
                        name="isDoctor"
                        checked={isDoctor}
                        onChange={(e) => setIsDoctor(e.target.checked)}
                      />{" "}
                      {t("Register.isDoctor")}
                    </label>
                  </div>
                  <div className="bestabib__form-group">
                    <label className="bestabib__label">
                      {t("Doctor.Gender")}
                    </label>
                    <select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      className="bestabib__input"
                    >
                      <option value="M">{t("Doctor.Male")}</option>
                      <option value="F">{t("Doctor.Female")}</option>
                    </select>
                  </div>
                  <div className="bestabib__form-group">
                    <label className="bestabib__label">
                      {t("User.First_Name")}
                    </label>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="bestabib__input"
                    />
                  </div>
                  <div className="bestabib__form-group">
                    <label className="bestabib__label">
                      {t("User.Last_Name")}
                    </label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="bestabib__input"
                    />
                  </div>
                  <div className="bestabib__form-group">
                    <label className="bestabib__label">
                      {t("User.Email")}:
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="bestabib__input"
                    />
                  </div>
                  {isDoctor && (
                    <>
                      <div className="bestabib__form-group">
                        <label className="bestabib__label">
                          {t("Doctor.Phone")}:
                        </label>
                        <input
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          className="bestabib__input"
                        />
                      </div>

                      <div className="bestabib__form-group">
                        <label className="bestabib__label">
                          {t("Doctor.Speciality")}:
                        </label>
                        <input
                          type="text"
                          value={doctorProfile.speciality}
                          onChange={(e) =>
                            setDoctorProfile({
                              ...doctorProfile,
                              speciality: e.target.value,
                            })
                          }
                          className="bestabib__input"
                        />
                      </div>
                      <div className="bestabib__form-group">
                        <label className="bestabib__label">
                          {t("Doctor.Description")}:
                        </label>
                        <textarea
                          value={doctorProfile.description}
                          onChange={(e) =>
                            setDoctorProfile({
                              ...doctorProfile,
                              description: e.target.value,
                            })
                          }
                          className="bestabib__input"
                        />
                      </div>
                      {/* Add more doctor-specific fields as necessary */}
                    </>
                  )}
                </div>
                <div className="bestabib__profile-account-info-address">
                  <div className="bestabib__form-address">
                    <div className="bestabib__form-group">
                      <label className="bestabib__label">
                        {" "}
                        {t("Doctor.Profile_Image")} :
                      </label>
                      <input
                        type="file"
                        onChange={(e) => {
                          if (e.target.files && e.target.files.length > 0) {
                            setDoctorImage(e.target.files[0]);
                          }
                        }}
                        className="bestabib__input"
                      />
                    </div>
                    <div className="bestabib__form-group">
                      <label className="bestabib__label">
                        {t("Address.Street")}:
                      </label>
                      <input
                        type="text"
                        value={address.street}
                        onChange={(e) =>
                          setAddress({ ...address, street: e.target.value })
                        }
                        className="bestabib__input"
                      />
                    </div>
                    <div className="bestabib__form-group">
                      <label className="bestabib__label">
                        {t("Address.City")}:
                      </label>
                      <input
                        type="text"
                        value={address.city}
                        onChange={(e) =>
                          setAddress({ ...address, city: e.target.value })
                        }
                        className="bestabib__input"
                      />
                    </div>
                    {/* <div className="bestabib__form-group">
                      <label className="bestabib__label">
                        {t("Address State")}:
                      </label>
                      <input
                        type="text"
                        value={address.state}
                        onChange={(e) =>
                          setAddress({ ...address, state: e.target.value })
                        }
                        className="bestabib__input"
                      />
                    </div> */}
                    <div className="bestabib__form-group">
                      <label className="bestabib__label">
                        {t("Address.Postal_Code")}:
                      </label>
                      <input
                        type="text"
                        value={address.postal_code}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            postal_code: e.target.value,
                          })
                        }
                        className="bestabib__input"
                      />
                    </div>
                    <div className="bestabib__form-group">
                      <label className="bestabib__label">
                        {t("Address.Country")}:
                      </label>
                      <input
                        type="text"
                        value={address.country}
                        onChange={(e) =>
                          setAddress({ ...address, country: e.target.value })
                        }
                        className="bestabib__input"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" className="bestabib__button">
                {t("Profile.Update")}
              </button>
            </form>
          </div>
        )}
        {activeSection === "updatePassword" && <UpdatePasswordSection />}
      </div>
    </div>
  );
};

export default ProfileScreen;
