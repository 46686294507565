import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { SearchBox, Doctor, DoctorDetails, Pagination } from "../../components";
import { listDoctors } from "../../actions/doctorActions";
import { DoctorType } from "../../types";
import { useNavigate } from "react-router-dom";
import "./home.css";

const HomeScreen = () => {
  const { t } = useTranslation();
  const [selectedDoctor, setSelectedDoctor] = useState<DoctorType | null>(null);
  const navigate = useNavigate();
  const codeLng = window.location.pathname.substring(1, 3) || "fr";
  const {
    error,
    loading: loadingDoctors,
    doctors,
    totalPages,
  } = useSelector((state: any) => state.doctorList);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch<any>(
      listDoctors({
        doctor_name: "",
        specialty: "",
        city: "",
        gender: "",
        currentPage,
      })
    );
  }, [dispatch, currentPage]);

  const createSlug = (name: string) => {
    return name
      .toLowerCase()
      .normalize("NFD") // Decompose accented characters into base characters and diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .replace(/[^a-z0-9 -]/g, "") // Remove any remaining non-alphanumeric characters, except spaces and hyphens
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  };

  const handleDoctorClick = (doctor: DoctorType) => {
    setSelectedDoctor(doctor);
    const doctorSlug = `${createSlug(doctor.first_name ?? "")}-${createSlug(
      doctor.last_name ?? ""
    )}`;
    const specialtySlug = doctor?.speciality
      ? createSlug(doctor?.speciality)
      : "medecin";

    if (window.innerWidth < 920) {
      navigate(`/${codeLng}/${specialtySlug}/${doctorSlug}`, {
        state: { doctorId: doctor.id },
      });
    }
  };

  const renderDoctorDetailsLink = (doctor: DoctorType) => (
    <div
      key={doctor.id}
      className={`bestabib__home-card-doctor ${
        selectedDoctor === doctor ? "showSelectedDoctor" : ""
      }`}
      onClick={() => handleDoctorClick(doctor)}
    >
      <Doctor doctor={doctor} />
    </div>
  );

  return (
    <div className="bestabib__home-container">
      <div className="bestabib__home-search-box">
        <SearchBox />
      </div>
      <div className="bestabib__home-doctor-list-details">
        <div className="bestabib__home-doctor-list">
          <h1>{t("Doctor.Best Doctors")}</h1>
          {loadingDoctors ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : doctors?.length ? (
            <>
              {doctors.map((doctor: any, index: number) => (
                <div key={`${doctor.id}-${index}`}>
                  {renderDoctorDetailsLink(doctor)}
                </div>
              ))}
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </>
          ) : (
            <p>No Doctor available</p>
          )}
        </div>
        {selectedDoctor && (
          <div className="bestabib__home-doctor-details">
            <DoctorDetails selectedDoctor={selectedDoctor} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeScreen;
